import React, { useState, useEffect } from 'react';
import {
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography,
  List, 
  ListItem, 
  ListItemText, 
  Divider, 
  IconButton, 
  ListItemIcon,
  Checkbox,
  Box,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { openDB } from 'idb';

const StoredFilesPopup = ({ open, handleClose, handleNext }) => {
  const [storedFiles, setStoredFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  // Opens or creates a 'fileStore' database with an object store 'files' if it doesn't exist
  const openDatabase = async () => {
    const db = await openDB('fileStore', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
        }
      },
    });
    return db;
  };

  //Fetch files from indexedDB
  const fetchFilesFromIndexedDB = async () => {
    setLoading(true);
    const db = await openDatabase();
    const tx = db.transaction('files', 'readonly');
    const store = tx.objectStore('files');
    const files = await store.getAll();
    // console.log('Files fetched from IndexedDB:', files);  
    setStoredFiles(files);
    setLoading(false);
  };

  //Delete files from IndexedDB
  const deleteFilesFromIndexedDB = async (idsToDelete) => {
    const db = await openDatabase();
    const tx = db.transaction('files', 'readwrite');
    const store = tx.objectStore('files');
    for (const id of idsToDelete) {
      await store.delete(id);
    }
    await tx.done;
    fetchFilesFromIndexedDB(); 
  };

  const handleSelectFile = (file) => {
    setSelectedFiles((prevSelected) =>
      prevSelected.includes(file.id)
        ? prevSelected.filter((id) => id !== file.id)
        : [...prevSelected, file.id]
    );
  };

  //delete selected files
  const handleDeleteSelected = () => {
    deleteFilesFromIndexedDB(selectedFiles);
    setSelectedFiles([]);
  };

  //Delete all function
  const handleDeleteAll = () => {
    const allIds = storedFiles.map((file) => file.id);
    deleteFilesFromIndexedDB(allIds);
    setSelectedFiles([]); 
  };

  useEffect(() => {
    if (open) {
      // console.log('StoredFilesPopup opened, fetching files...');
      fetchFilesFromIndexedDB();
    }
  }, [open]);


  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6" component="div" align="center">
          Already Uploaded Files
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'grey.500',
            '&:hover': {
              color: 'black',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : storedFiles.length > 0 ? (
          <>
            <List
              sx={{
                backgroundColor: '#f5f5f5',
                padding: '15px',
                borderRadius: '8px',
                maxHeight: '400px',
                overflowY: 'auto',
              }}
            >
              {storedFiles.map((file, index) => (
                <React.Fragment key={file.id}>
                  <ListItem
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      padding: '10px',
                      backgroundColor: '#ffffff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      checked={selectedFiles.includes(file.id)}
                      onChange={() => handleSelectFile(file)}
                      sx={{ marginRight: '10px' }}
                    />
                    <ListItemIcon>
                      {file.name.endsWith('.zip') || file.type === 'application/zip' ? (
                        <InsertDriveFileIcon sx={{ fontSize: 50, color: '#808080' }} />
                      ) : file.base64 ? (
                        <img
                          src={file.base64}
                          alt={file.originalName}
                          style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px', marginRight: '10px' }}
                        />
                      ) : (
                        <InsertDriveFileIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={file.originalName} />
                  </ListItem>
                  {index < storedFiles.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <Button
                onClick={handleDeleteSelected}
                color="error"
                variant="outlined"
                disabled={selectedFiles.length === 0}
                sx={{ margin: '0 8px' }}
              >
                Delete Selected
              </Button>
              <Button
                onClick={handleDeleteAll}
                color="error"
                variant="contained"
                sx={{ margin: '0 8px' }}
              >
                Delete All
              </Button>
            </Box>
          </>
        ) : (
          <Typography variant="body1">No files stored.</Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '16px',
        }}
      >
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          sx={{ margin: '0 8px' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleNext}
          color="primary"
          variant="contained"
          sx={{ margin: '0 8px' }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StoredFilesPopup;
