import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Cookies from 'js-cookie';
import axios from "axios";
import {path} from '../../apiCentral/path.js'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({   
  prompt : "select_account"
});

// Implement Google Sign-In
const signInWithGoogle = async (navigate, setIsGoogleLoading) => {
  try {
    
    const result = await signInWithPopup(auth, provider);
    setIsGoogleLoading(true);
    const user = result.user;
    const idToken = await user.getIdToken(true);
    const expiresIn = 3590; // Token validity in seconds
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    Cookies.set('token', idToken, { expires: expirationDate });

    // Perform backend authentication similar to email/password login
    const response = await axios.post(
      path?.loginUrl, {},
      {
        headers: {
          authorization: `${idToken}`,
        },
      }
    );

    // Check backend response and set additional cookies
    if (response?.status === 200) {
      Cookies.remove("orgDomain");
      Cookies.remove("orgDisplayName");
      Cookies.remove("userType");
      
      if (response?.data?.orgDomain) {
        Cookies.set("orgDomain", response?.data?.orgDomain, {
          expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 10)
        });
      }
      if (response?.data?.orgDisplayName) {
        Cookies.set("orgDisplayName", response?.data?.orgDisplayName, {
          expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 10)
        });
      }
      if (response?.data?.userType) {
        Cookies.set("userType", response?.data?.userType, {
          expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 10)
        });
      }
      // Redirect to home page after successful authentication
      setIsGoogleLoading(false);
      navigate("/CardHouse?tab=analytics");
    } else {
      Cookies.remove("token");
      setIsGoogleLoading(false);
    }
  } catch (error) {
    console.error("Error during Google Sign-In:", error);
    if (error.code === "auth/popup-closed-by-user") {
      console.log("Popup closed without signing in.");
    }
    
    if (error?.response?.data) {
      alert(error?.response?.data)
    }
    Cookies.remove("token");
    setIsGoogleLoading(false);
  }finally {
    setIsGoogleLoading(false);
  }
};

const refreshToken = () => {
  onAuthStateChanged(auth, async (user) => {
    if (!Cookies.get('token')) return;

    if (user) {
      try {
        const idToken = await user.getIdToken(true);
        // console.log("REFRESHING TOKEN: " + idToken);
        const expiresIn = 3590; // Token validity in seconds
        const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
        Cookies.set('token', idToken, { expires: expirationDate });
      } catch (error) {
        console.error('Error refreshing token:', error);
        Cookies.remove('token');
      }
    } else {
      Cookies.remove('token');
    }
  });
};


export { auth, app, refreshToken, signInWithGoogle };
