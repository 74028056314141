import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Grid,
  Typography,
  CardMedia,
  IconButton,
  Tooltip,
  InputAdornment,
  CardContent,
  Card,
  MenuItem,
  OutlinedInput,
  Select,
  InputLabel,
  FormControl,
  Chip,
  AppBar,
  Toolbar,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LaunchIcon from "@mui/icons-material/Launch";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import WebsitePopup from "./popups/DraftWebsitePopup";
import Cookies from 'js-cookie';
import { path } from "../../apiCentral/path";

const fetchImageAsBase64 = async (url, authToken) => {
  try {
    const response = await axios.post(`https://cdn.hayashirsha.com/${url}`, null, {
      headers: {
        'Authorization': authToken,
      },
      responseType: 'arraybuffer',
    });

    const base64 = btoa(
      new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    return `data:image/jpeg;base64,${base64}`;
  } catch (error) {
    // console.error('Failed to fetch image:', error);
    return null;
  }
};

const DraftDetails = ({ editMode, data, userCategories, setEditMode }) => {
  const [websitePopupOpen, setWebsitePopupOpen] = useState(false);
  const [urlToRedirect, setUrlToRedirect] = useState('');
  const [formData, setFormData] = useState(data);
  const token = Cookies.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [frontImgBase64, setFrontImgBase64] = useState(null);
  const [backImgBase64, setBackImgBase64] = useState(null);
  // console.log(formData._id);

  const formatURL = (url) => url.startsWith('https://') ? url : `https://${url}`;

  //To fetch the front and back images
  useEffect(() => {
    const fetchImages = async () => {
      if (formData.front_img_bucket) {
        const frontImg = await fetchImageAsBase64(formData.front_img_bucket, token);
        setFrontImgBase64(frontImg);
      }
      if (formData.back_img_bucket) {
        const backImg = await fetchImageAsBase64(formData.back_img_bucket, token);
        setBackImgBase64(backImg);
      }
    };

    fetchImages();
  }, [formData.front_img_bucket, formData.back_img_bucket, token]);

  const handleWebsitePopupOpen = (url) => {
    setUrlToRedirect(url);
    setWebsitePopupOpen(true);
    window.gtag('event', 'redirect_to_website', {
      event_category: 'User Navigation',
      event_label: `Redirect to: ${url}`,
      value: 1, // 1 for successful redirection popup
      debug_mode: true,
    });
  };

  // Handle form input changes: split comma-separated categories into an array, or set the value directly for other fields.
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "categories") {
      setFormData({
        ...formData,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCopyClick = (e, contentType, contentToCopy) => {
    e.preventDefault();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(contentToCopy).catch((error) => {
        // console.error("COPY FAILED:", error);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = contentToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  };


  const handleWebsitePopupClose = () => {
    setWebsitePopupOpen(false);
    setUrlToRedirect('');
  };

  const handleWebsiteConfirm = () => {
    if (urlToRedirect) window.open(urlToRedirect);
    handleWebsitePopupClose();

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Save form data changes by sending only the updated fields to the server.
  const handleSave = async () => {
    try {
      const updatedData = {
        _id: formData._id,
        ...Object.keys(formData).reduce((changes, key) => {
          if (formData[key] !== data[key]) {
            changes[key] = formData[key];
          }
          return changes;
        }, {})
      };
      setIsLoading(true);
      const response = await axios.put(path.updateDraft, updatedData, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
      window.gtag('event', 'update_card', {
        event_category: 'Card Management',
        event_label: 'edit',
        value: updatedData ? 1 : 0, // 1 for successful update, 0 for failed
        debug_mode: true,
      });
      if (response.status === 200) {
        // console.log('Data saved successfully:', response.data);
        setEditMode(false);
      } else {
        // console.error('Failed to save data:', response);
      }
    } catch (error) {
      // console.error('Error saving data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setFormData(data);
    setEditMode(false);
  };

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div>
      {editMode && (
        <Toolbar style={{ justifyContent: "flex-end" }}>
          <Tooltip title="Save">
            <IconButton onClick={handleSave} color="primary">
              <CheckIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton onClick={handleCancel} color="error">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      )}
      <Grid container spacing={2} marginBottom={5} style={{ backgroundColor: "#ECECEC" }}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            name="person_name"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.person_name || ''}
            disabled={!editMode}
            style={{ backgroundColor: "white", height: "60px" }}
            InputProps={{
              style: { padding: '30px 0px', height: '40px' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            name="company_name"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.company_name || ''}
            disabled={!editMode}
            style={{ backgroundColor: "white", height: "60px" }}
            InputProps={{
              style: { padding: '30px 0px', height: '40px' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Designation"
            variant="outlined"
            fullWidth
            name="designation"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.designation || ''}
            disabled={!editMode}
            style={{ backgroundColor: "white", height: "60px" }}
            InputProps={{
              style: { padding: '30px 0px', height: '40px' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Emails"
            variant="outlined"
            fullWidth
            name="emails"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.emails || ''}
            disabled={!editMode}
            style={{ backgroundColor: "white", height: "60px" }}
            InputProps={{
              endAdornment: editMode && (
                <InputAdornment position="end">
                  <Tooltip title="Copy">
                    <IconButton onClick={(e) => handleCopyClick(e, "emails", formData?.emails || '')}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              style: { padding: '30px 0px', height: '40px' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Mobile Numbers"
            variant="outlined"
            fullWidth
            name="mobile_numbers"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.mobile_numbers || ''}
            disabled={!editMode}
            style={{ backgroundColor: "white", height: "60px" }}
            InputProps={{
              endAdornment: editMode && (
                <InputAdornment position="end">
                  <Tooltip title="Copy">
                    <IconButton onClick={(e) => handleCopyClick(e, "mobile_numbers", formData?.mobile_numbers || '')}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              style: { padding: '30px 0px', height: '40px' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Website/url"
            variant="outlined"
            fullWidth
            name="website"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.website || ''}
            disabled={!editMode}
            style={{ backgroundColor: "white", height: "60px" }}
            InputProps={{
              endAdornment: editMode && formData?.website && (
                <InputAdornment position="end">
                  <Tooltip title="Visit Website">
                    <IconButton onClick={() => handleWebsitePopupOpen(formatURL(formData?.website || ''))}>
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              style: { padding: '30px 0px', height: '40px' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            name="address"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.address || ''}
            disabled={!editMode}
            style={{ backgroundColor: "white", height: "60px" }}
            InputProps={{
              style: { padding: '30px 0px', height: '40px' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="category-select-label">User Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-multiple-chip"
              multiple
              name="categories"
              value={formData.categories || []}
              onChange={handleChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="User Category"
                />
              }
              renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </div>
              )}
              disabled={!editMode}
            >
              {userCategories.map((category, index) => (
                <MenuItem key={index} value={category?.userCategory}>
                  {category?.userCategory}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Front Image</Typography>
              {frontImgBase64 && (
                <CardMedia
                  component="img"
                  image={frontImgBase64}
                  alt="Front Image"
                  style={{
                    width: '100%',
                    height: '300px',
                    objectFit: 'contain',
                    borderRadius: 0,
                  }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          {backImgBase64 && (
            <Card>
              <CardContent>
                <Typography variant="h6">Back Image</Typography>
                <CardMedia
                  component="img"
                  image={backImgBase64}
                  alt="Back Image"
                  style={{
                    width: '100%',
                    height: '300px',
                    objectFit: 'contain',
                    borderRadius: 0,
                  }}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <WebsitePopup
        isPopupOpen={websitePopupOpen}
        handlePopupClose={handleWebsitePopupClose}
        handleConfirm={handleWebsiteConfirm}
        urlToRedirect={urlToRedirect}
      />
    </div>
  );
};

export default DraftDetails;
