import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { InputAdornment, TextField, FormControl, MenuItem, InputLabel, Select } from "@mui/material";
import apiHelper from "../../../apiCentral/apiHelper";
import { saveAs } from "file-saver";
import Cookies from "js-cookie";
const { path } = require("../../../apiCentral/path");
const _ = require("lodash");

function AddSalesRepresentativePopup({
  isPopupOpen,
  handlePopupClose,
  users,
  setUsers,
  handleGetUserCall,
}) {
  const [data, setData] = useState({
    display_name: "",
    gender: "",
    email: "",
    phone_number: "",
    password: "",
  });
  let orgDomain = Cookies.get("orgDomain");
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateLocalPart = (localPart) => {
    if (!localPart) return true;
    const regex = /^[a-zA-Z0-9.]+$/;
    return regex.test(localPart) && !localPart.includes('@');
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return true;
    return /^[0-9]{10}$/.test(phoneNumber);
  };

  const validatePassword = (password) => {
    if (!password) return true;
    return password.length >= 6;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response;
    try {
      if (!validateLocalPart(data?.email) || !validatePhoneNumber(data?.phone_number) || !validatePassword(data?.password)) {
        alert("Please enter valid details");
        return;
      }
      const finalData = { ...data };
      finalData.email = _.toLower(finalData?.email) + (!_.isEmpty(orgDomain) ? orgDomain : "");
      if (users.some(user => _.toLower(user?.email) === _.toLower(finalData?.email))) {
        alert("Agent already exists");
        return;
      }
      setUsers(null);
      handlePopupClose();
      window.gtag('event', 'sales_rep_added', {
        event_category: 'User Management',
        event_label: `Added: ${finalData?.email}`,
        value: 1,
        debug_mode: true,
      });
      response = await apiHelper.post(path?.addUser, finalData);
      const fileName = `AGENT_${new Date().toISOString()}.txt`;
      const fileContent = `Email: ${finalData?.email}\nPassword: ${finalData?.password}`;
      const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, fileName);
      handleGetUserCall();
    } catch (error) {
      console.error(`Error occurred in handleSubmit: ${error}`);
      alert("Internal Server Error! Please Try Again");
    }
  };

  return (
    <>
      <Dialog
        open={isPopupOpen}
        onClose={handlePopupClose}
      >
        <DialogTitle>Add Sales Representative</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="display_name"
              label="Name"
              type="text"
              fullWidth
              value={data?.display_name}
              onChange={handleInputChange}
              required
            />
            <FormControl fullWidth required margin="dense">
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                id="gender"
                label="gender"
                name="gender"
                value={data?.gender}
                onChange={handleInputChange}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="text"
              fullWidth
              value={data?.email || ''}
              onChange={handleInputChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ pointerEvents: 'none', color: '#999' }}>
                    {!_.isEmpty(orgDomain) ? orgDomain : ""}
                  </InputAdornment>
                ),
              }}
              error={!validateLocalPart(data?.email)}
              helperText={!validateLocalPart(data?.email) ? "Invalid email format" : ""}
            />
            <TextField
              margin="dense"
              name="phone_number"
              label="Phone Number"
              fullWidth
              value={data?.phone_number}
              onChange={handleInputChange}
              error={!validatePhoneNumber(data?.phone_number)}
              helperText={!validatePhoneNumber(data?.phone_number) ? "Phone number should be 10 digits" : ""}
              required
            />
            <TextField
              margin="dense"
              name="password"
              label="Password"
              fullWidth
              value={data?.password}
              onChange={handleInputChange}
              error={!validatePassword(data?.password)}
              helperText={!validatePassword(data?.password) ? "Password should be at least 6 characters long" : ""}
              required
            />
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center", paddingBottom: "25px" }}>
            <Button onClick={handlePopupClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained" style={{ paddingLeft: '20px', paddingRight: "20px", marginLeft: "50px", paddingMargin: "20px" }}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog >
    </>
  );
}

export default AddSalesRepresentativePopup;
