import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import apiHelper from "../../../apiCentral/apiHelper";
import { path } from "../../../apiCentral/path";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import AddTaskRoundedIcon from "@mui/icons-material/AddTaskRounded";

const RevokePopup = ({
  revokeData,
  handleGetUserCall,
  setUsers,
  handleRevokePopupClose,
  isRevokePopupOpen
}) => {
  const { agentUid, isActive } = revokeData;

  const handleActiveStatusChange = async () => {
    let newActiveStatus = false
    if( isActive === null ){
        newActiveStatus = false
    }
    newActiveStatus = !isActive;
    setUsers(null);
    try {
      await apiHelper.put(`${path.updateUser}/${agentUid}`, {
        is_active: newActiveStatus,
      });
      window.gtag('event', newActiveStatus ? 'grant_access' : 'revoke_access', {
        event_category: 'User Management',
        event_label: agentUid,
        value: newActiveStatus ? 1 : 0, // 1 for granting access, 0 for revoking
        debug_mode: true,
      });
      handleGetUserCall();
      handleRevokePopupClose();
    } catch (error) {
      alert("Internal Server Error! Please Try Again");
      console.error(`Error occurred in handleActiveStatusChange: ${error}`);
    }
  };

  return (
    <Dialog open={isRevokePopupOpen} onClose={handleRevokePopupClose}>
    <DialogContent style={{width:'470px',height:'235px'}}>
        <DialogTitle style={{ textAlign: "left",marginBottom:"20px" }}>
          {isActive ? (
            <p style={{display:"flex",flexDirection:"row"}}>
              <WarningAmberRoundedIcon style={{ color: "#FFAB00",marginTop:"4px",marginRight:"10px"}} /> 
              <p>Revoke Employee</p>
            </p>
          ) : (
            <p style={{display:"flex",flexDirection:"row"}}>
              <AddTaskRoundedIcon style={{ color: "green",marginTop:"4px",marginRight:"10px" }} /> <p style={{marginleft:'10px'}}>Grant Employee</p>
            </p>
          )}
        </DialogTitle>
        <DialogContent
          style={{ textAlign: "left"}}
        >
          {isActive ? (
            <p>Do you want to revoke the employee?</p>
          ) : (
            <p>Do you want to grant the employee?</p>
          )}
        </DialogContent>
        <DialogActions style={{marginRight:'20px'}}>
        <Button
          onClick={(e) => handleRevokePopupClose(e)}
          style={{ color: "black" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleActiveStatusChange}
          style={{
            backgroundColor: isActive ? "#FFAB00" : "#28A745",
            color: "white",
          }}
          variant="contained"
        >
          {isActive ? "continue" : "Grant Access"}
        </Button>
      </DialogActions>
      </DialogContent>
      
    </Dialog>
  );
};

export default RevokePopup;
