import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  Button,
  Collapse,
  Box,
  Grid,
  InputAdornment,
  createTheme,
  ThemeProvider,
  Tooltip,
  Chip,
  Checkbox,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import apiHelper from "../../apiCentral/apiHelper";
import { path } from "../../apiCentral/path";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterPopup from "./popups/FilterPopup";
import CardDetails from "./CardDetails";
import { styled } from "@mui/system";
import { saveAs } from "file-saver";
import { updateSelectedFields } from "../../utils";
import { cardTypeMap } from "../../contants";
import TablePaginationActions from "./TablePaginationAction";
import maleIcon from "../../assets/icons/male_icon.svg";
import femaleIcon from "../../assets/icons/female_icon.svg";
import profileIcon from "../../assets/icons/profile_icon.svg";
import callIcon from "../../assets/icons/call_icon.svg";
import locationIcon from "../../assets/icons/location.svg";
import mailIcon from "../../assets/icons/mail_icon.svg";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddNewCardsPopup from "./popups/uploadPopups/AddNewCardsPopup";
import StoredFilesPopup from "./popups/uploadPopups/StoredFilesPopup";
import { useLocation } from 'react-router-dom';
import SelectUploadTypePopup from "./popups/uploadPopups/SelectUploadTypePopup";
import { openDB } from 'idb';
import MarkForImprovementPopup from "./popups/MarkForImprovementPopup";
import placeholder from '../../assets/icons/placeholder.svg';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import StarIcon from '@mui/icons-material/Star';
import Cookies from 'js-cookie';
import axios from "axios";

const _ = require("lodash");
const XLSX = require("xlsx");

const defaultPageSize = 10;

const initialRequest = {
  search: "",
  dateFilter: {
    startDate: null,
    endDate: null,
  },
  srFilter: [],
  typeFilter: [],
  cardCategoryFilter: [],
  pagination: {
    page: 0,
    size: defaultPageSize,
  },
};

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: 40,
            marginBottom: 20,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          marginBottom: 20,
        },
      },
    },
  },
});

function CardsContainer({ isBookmarked, usersMap, userCategories, userCategoriesColorMap, bookmarkedCards }) {
  const [cards, setCards] = useState(null);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState(initialRequest);
  const [filterView, setFilterView] = useState(false);
  const [expandableRows, setExpandableRows] = useState({});
  const [previousFilters, setPreviousFilters] = useState(initialRequest);
  const [previousRequest, setPreviousRequest] = useState(null);
  const [bookamrakLoader, setBookamrakLoader] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
  const maxPage = Math.max(Math.ceil(total / rowsPerPage) - 1, 0);
  const currentPage = Math.max(Math.min(request?.pagination?.page || 0, maxPage), 0);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [addNewCardsPopupOpen, setAddNewCardsPopupOpen] = useState(false);
  const [storedFilesPopupOpen, setStoredFilesPopupOpen] = useState(false);
  const [selectUploadTypePopupOpen, setSelectUploadTypePopupOpen] = useState(false);
  const [uploadType, setUploadType] = useState('');
  const [expandedFavicons, setExpandedFavicons] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const isSelectedAll = cards?.length > 0 && selectedRows.length === cards.length;
  const isSelectedNone = selectedRows.length === 0;
  const isIndeterminate = !isSelectedNone && !isSelectedAll;
  const selectedCount = selectedRows.length;
  const [isMarkedForImprovement, setIsMarkedForImprovement] = useState();
  const [markForImprovementDialogOpen, setMarkForImprovementDialogOpen] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSelectView, setIsSelectView] = useState(false);
  const [useEffectTriggered, setUseEffectTriggered] = useState(false);
  const [loadingFavicons, setLoadingFavicons] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  // Function to toggle checkbox visibility
  const openSelectView = (value) => {
    window.gtag('event', 'select', {
      event_category: 'Selection',
      event_label: value ? 'Open Select View' : 'Close Select View',
      value: 1,
      debug_mode: true,
    });
    setShowCheckboxes(prevState => !prevState);
    setIsSelectView(prevState => !prevState);
  };

  useEffect(() => {
    // console.log('Query parameter changed:', tab);
    // Close selectView when the query parameter changes
    setIsSelectView(false);
    setShowCheckboxes(false);
  }, [tab]);

  // You can add a console log here to check the states
  useEffect(() => {
    // console.log('isSelectView:', isSelectView, 'showCheckboxes:', showCheckboxes);
  }, [isSelectView, showCheckboxes]);

  const handleMarkForImprovementDialogOpen = (e) => {
    e.preventDefault();
    setMarkForImprovementDialogOpen(true);
  };

  const handleMarkForImprovementDialogClose = () => {
    window.gtag('event', 'Mark For Improvement', {
      event_category: 'Dialog',
      event_label: 'Mark for Improvement Dialog Opened',
      value: 1,
      debug_mode: true,
    });
    setMarkForImprovementDialogOpen(false);
  };

  const handleSelectAllClick = (event) => {
    // If already indeterminate or if the checkbox is unchecked, we want to clear all selections
    window.gtag('event', 'select_all', {
      event_category: 'Selection',
      event_label: event.target.checked ? 'Select All' : 'Deselect All',
      value: 1,
      debug_mode: true,
    });
    if (isIndeterminate || !event.target.checked) {
      setSelectedRows([]);
    } else {
      // If the checkbox is checked, select all rows
      const newSelecteds = cards.map((row) => row.uid);
      setSelectedRows(newSelecteds);
    }
  };

  const handleClick = (event, uid) => {
    if (!isSelectView) return;

    const selectedIndex = selectedRows.indexOf(uid);
    let newSelected = [];

    if (selectedIndex === -1) {
      // Add the new row to selectedRows if not already selected
      newSelected = [...selectedRows, uid];
    } else {
      // Remove the selected row
      newSelected = selectedRows.filter((selectedUid) => selectedUid !== uid);
    }

    setSelectedRows(newSelected);
  };

  // Check if the row is selected based on uid.
  const isSelected = (uid) => selectedRows.indexOf(uid) !== -1;

  // Toggle the expand/collapse state for favicons by uid.
  const handleFaviconsExpandCollapse = (e, uid) => {
    e.stopPropagation();
    window.gtag('event', 'Favicon expansion', {
      event_category: 'Favicon Expansion',
      event_label: expandedFavicons[uid] ? 'Collapse' : 'Expand',
      value: 1,
      debug_mode: true,
    });
    setExpandedFavicons((prev) => ({
      ...prev,
      [uid]: !prev[uid],
    }));
  };

  // Open the appropriate popup based on whether files are stored in IndexedDB.
  const handlePopupOpen = async () => {
    window.gtag('event', 'upload', {
      event_category: 'Popup',
      event_label: 'File Storage Check',
      debug_mode: true,
    });
    const openDatabase = async () => {
      return await openDB('fileStore', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('files')) {
            db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
          }
        },
      });
    };

    const db = await openDatabase();
    const tx = db.transaction('files', 'readonly');
    const store = tx.objectStore('files');
    const storedFiles = await store.getAll();

    if (storedFiles.length > 0) {
      setStoredFilesPopupOpen(true);
    } else {
      setSelectUploadTypePopupOpen(true);
    }
  };

  //Add New Cards Pop up
  const handleAddNewCardsPopupClose = () => setAddNewCardsPopupOpen(false);
  const handleStoredFilesPopupClose = () => setStoredFilesPopupOpen(false);
  const handleSelectUploadTypePopupClose = () => setSelectUploadTypePopupOpen(false);

  const handleUploadTypeSelection = (selectedOption) => {
    // console.log(selectedOption);
    setUploadType(selectedOption);
    setAddNewCardsPopupOpen(true);
  };

  const handleCategoryExpandCollapse = (e, uid) => {
    e.stopPropagation();
    setExpandedCategories((prev) => ({
      ...prev,
      [uid]: !prev[uid],
    }));
  };

  useEffect(() => {
    setExpandableRows({});
    setRequest(initialRequest);
    handleGetCardsCall(initialRequest);
    setPreviousFilters(initialRequest);
  }, [isBookmarked]);

  useEffect(() => {
    // console.log("use effect triggered");
    if (useEffectTriggered === true) {
      return;
    }
    setUseEffectTriggered(true)

    const updateFavicons = async () => {
      let sample = ""
      const updatedCards = await Promise.all(cards.map(async (row) => {

        const updatedFavicons = (!_.isEmpty(row.favicons) && row.favicons[0].split('/')[0] === "favicons") ? await fetchImageFromCloudflare(row.favicons) : undefined;
        if (!_.isEmpty(row.favicons)) sample = row.favicons[0]
        return { ...row, favicons: updatedFavicons };
      }));

      setCards(updatedCards);
    };

    if (!_.isEmpty(cards) && cards.length > 0) {
      updateFavicons();
    }
    setUseEffectTriggered(false)
  }, [cards?.length]);

  const fetchImageFromCloudflare = async (favicons) => {
    if (!favicons || favicons.length === 0) return;
    setLoadingFavicons(true);
    const authToken = Cookies.get('token');

    try {
      const updatedFavicons = await Promise.all(
        favicons.map(async (favicon) => {
          const response = await axios.post(`https://cdn.hayashirsha.com/${favicon}`, null, {
            headers: {
              Authorization: authToken,
            },
            responseType: 'arraybuffer',
          });

          const base64 = btoa(
            new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
          );

          return `data:image/jpeg;base64,${base64}`;
        })
      );

      return updatedFavicons;
    } finally {
      setLoadingFavicons(false); // Ensures loading stops even if there's an error
    }
  };

  const handleGetCardsCall = async (finalRequest) => {
    setCards(null);
    setTotal(null);
    let response = await getCards(finalRequest);
    setTotal(response?.data?.total);
    setCards(response?.data?.results?.cards);
  };

  const getCards = async (incomingRequest) => {
    try {
      let finalRequest = _.cloneDeep(incomingRequest);
      if (isBookmarked) {
        finalRequest["bookmarkFilter"] = true;
      } else {
        finalRequest["bookmarkFilter"] = false;
      }
      if (finalRequest?.dateFilter?.startDate) {
        finalRequest.dateFilter.startDate = new Date(
          new Date(finalRequest.dateFilter.startDate).setHours(0, 0, 0, 0)
        ).getTime();
      }
      if (finalRequest?.dateFilter?.endDate) {
        finalRequest.dateFilter.endDate = new Date(
          new Date(finalRequest.dateFilter.endDate).setHours(23, 59, 59, 999)
        ).getTime();
      }
      if (!_.isNil(cards) && cards.length > 0) {
        finalRequest.pagination.lastDocId = cards[cards.length - 1].uid;
        finalRequest.pagination.firstDocId = cards[0].uid;
      }
      setPreviousRequest(finalRequest);
      if (finalRequest.pagination.page === Math.floor(total / finalRequest.pagination.size) && finalRequest.pagination.page !== 0) {
        finalRequest.pagination.direction = "end"
      } else if (finalRequest.pagination.page === 0) {
        finalRequest.pagination.direction = "start"
      } else if (previousRequest && previousRequest.pagination.page < finalRequest.pagination.page) {
        finalRequest.pagination.direction = "increase"
      } else if (previousRequest && previousRequest.pagination.page - 1 === finalRequest.pagination.page) {
        finalRequest.pagination.direction = "decrease"
      }

      return await apiHelper.post(path?.getCards, finalRequest);
    } catch (error) {
      alert("Internal Server Error! Please Try Again");
    }
  };

  const handleUpdateCardsCall = async (dataToUpdate, uid, index) => {
    if (_.isEmpty(uid)) {
      alert(`Unable to process request because uid is not present.`);
      return null;
    }
    let response;
    try {
      response = await apiHelper.put(path?.updateCard + uid, dataToUpdate);
      // console.log("RESPONSE", JSON.stringify(response.data));
      return response?.data;
    } catch (error) {
      alert("Internal Server Error! Please Try Again");
      return null;
    }
  };

  const handleRequestChange = (name, value) => {
    let tempRequest;
    // console.log("NAME ", name, "VALUE", value);
    switch (name) {
      case "search":
        setRequest({ ...request, search: value });
        break;
      case "startDate":
        setRequest({
          ...request,
          dateFilter: { ...request.dateFilter, startDate: value },
        });
        break;
      case "endDate":
        setRequest({
          ...request,
          dateFilter: { ...request.dateFilter, endDate: value },
        });
        break;
      case "bothDates":
        setRequest({
          ...request,
          dateFilter: { startDate: value, endDate: value },
        });
        break;
      case "srFilter":
        setRequest({ ...request, srFilter: value });
        break;
      case "cardCategoryFilter":
        setRequest({ ...request, cardCategoryFilter: value });
        break;
      case "typeFilter":
        value === "deselect" ? setRequest({ ...request, typeFilter: [] }) : setRequest({ ...request, typeFilter: [value] });
        break;
      case "page":
        tempRequest = _.cloneDeep(request);
        tempRequest.pagination = { ...tempRequest.pagination, page: value };
        // console.log("TEMP REQUEST ", JSON.stringify(tempRequest));
        setPreviousFilters(tempRequest);
        handleGetCardsCall(tempRequest);
        setExpandableRows({});
        setExpandedCategories({});
        setRequest(tempRequest);
        break;
      case "size":
        tempRequest = _.cloneDeep(request);
        tempRequest.pagination = { page: 0, size: value };
        // console.log("TEMP REQUEST ", JSON.stringify(tempRequest));
        setPreviousFilters(tempRequest);
        handleGetCardsCall(tempRequest);
        setExpandableRows({});
        setExpandedCategories({});
        setRequest(tempRequest);
        break;
      case "filterReset":
        setExpandableRows({});
        setExpandedCategories({});
        handleGetCardsCall(initialRequest);
        setRequest(initialRequest);
        setPreviousFilters(initialRequest);
        break;
      case "reset":
        handleGetCardsCall(previousFilters);
        setExpandableRows({});
        setExpandedCategories({});
        setRequest(previousFilters);
        break;
      case "filterCancel":
        tempRequest = _.cloneDeep(previousFilters);
        tempRequest.pagination = request.pagination;
        tempRequest.search = request.search;
        setRequest(tempRequest);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.gtag('event', 'filter', {
      event_category: 'Form',
      event_label: 'Filter Submission',
      value: 1,  // You can modify this based on your needs
      debug_mode: true,
    });
    if (filterView) setFilterView(false);
    // Changing it to page 0 will automatically call the API
    handleRequestChange("page", 0);
  };

  const handleBookmarkClick = async (e, value, uid, index) => {
    e?.preventDefault();  // Prevent default action if it's triggered from an event (like direct click on bookmark symbol)

    window.gtag('event', 'bookmark_click', {
      event_category: 'User Actions',
      event_label: 'Bookmark', // Track by index or another identifier
      value: value,
      debug_mode: true,
    });
    // Loader for individual click or selected rows
    setBookamrakLoader((prevState) => {
      const updatedState = { ...prevState };

      // Handle when bookmark is clicked directly
      if (typeof index !== 'undefined' && uid) {
        updatedState[index] = true;
      }

      // Handle when multiple rows are selected using checkboxes
      if (selectedRows && selectedRows.length > 0) {
        selectedRows.forEach((uid) => {
          const index = cards.findIndex(card => card.uid === uid);
          if (index !== -1) {
            updatedState[index] = true;
          }
        });
      }

      return updatedState;
    });

    // Bookmark cards - handle both selected and individual cards
    let updatedCards = [];

    if (selectedRows && selectedRows.length > 0) {
      // Case: Checkbox selection - Bookmark selected rows
      updatedCards = await Promise.all(
        selectedRows.map(async (uid) => {
          const cardIndex = cards.findIndex(card => card.uid === uid);
          const currentCard = cards[cardIndex];
          const updatedValue = !currentCard.isBookmarked; // Toggle bookmark status
          const updatedCard = await handleUpdateCardsCall({ isBookmarked: updatedValue }, uid, cardIndex);
          return { updatedCard, cardIndex };
        })
      );
    } else if (typeof index !== 'undefined' && uid) {
      // Case: Direct bookmark symbol click
      let updatedValue = true;
      if (value === true) {
        updatedValue = false;  // Toggle value
      }
      const updatedCard = await handleUpdateCardsCall({ isBookmarked: updatedValue }, uid, index);
      updatedCards.push({ updatedCard, cardIndex: index });
    }

    // Update the card state with the updated cards
    const tempCards = _.cloneDeep(cards);
    updatedCards.forEach(({ updatedCard, cardIndex }) => {
      if (updatedCard) {
        tempCards[cardIndex] = updateSelectedFields(updatedCard, tempCards[cardIndex]);
      }
    });

    setCards(tempCards);

    // Turn off loader for both individual and selected rows
    setBookamrakLoader((prevState) => {
      const updatedState = { ...prevState };

      // Handle individual bookmark click
      if (typeof index !== 'undefined' && uid) {
        updatedState[index] = false;
      }

      // Handle checkbox selection
      if (selectedRows && selectedRows.length > 0) {
        selectedRows.forEach((uid) => {
          const index = cards.findIndex(card => card.uid === uid);
          if (index !== -1) {
            updatedState[index] = false;
          }
        });
      }

      return updatedState;
    });
  };

  const handleExportClick = (e) => {
    e.preventDefault();
    window.gtag('event', 'Export', {
      event_category: 'Export',
      event_label: 'Export Button Clicked',
      value: 1,  // You can adjust the value as per your requirement
      debug_mode: true,
    });
    setOpen(true);
  };

  const handleConfirmExport = async (e) => {
    e?.preventDefault();
    setOpen(false); // Close the export modal/dialog if applicable

    try {
      // Loader for export

      // Fetch all card data (no pagination limit for exporting all cards)
      let finalRequest = { ...request };

      // If no rows are selected, fetch all cards (ensure no pagination limit)
      if (!selectedRows || selectedRows.length === 0) {
        finalRequest.pagination = {
          page: 0,
          size: Number.MAX_SAFE_INTEGER, // Fetch all records, no limit
        };
      } else {
        // If rows are selected, no need to fetch all, since selected cards are a subset of the currently fetched data
        finalRequest.pagination = {
          page: 0,
          size: 100, // You may adjust this if necessary, though it won't affect selected rows.
        };
      }

      let response = await getCards(finalRequest);
      if (response?.data?.results?.cards?.length === 0) {
        alert("No Data to Export");
        return;
      }

      // Determine cards to export based on selected rows or export all
      let cardsToExport = [];

      if (selectedRows && selectedRows.length > 0) {
        // Case: Export only the selected rows
        cardsToExport = response.data.results.cards.filter(card => selectedRows.includes(card.uid));
      } else {
        // Case: Export all cards
        cardsToExport = response.data.results.cards;
      }

      if (cardsToExport.length === 0) {
        alert("No Data to Export");
        return;
      }

      // Prepare data for Excel export
      const excelData = cardsToExport.map((card) => ({
        Name: card?.person_name || "",
        Company: card?.company_name || "",
        Designation: card?.designation || "",
        User: usersMap?.[card?.user_id]?.displayName || "",
        Categories: Array.isArray(card?.categories) ? card.categories.join(", ") : "",
        Emails: Array.isArray(card?.emails) ? card.emails.join(", ") : "",
        ["Mobile Numbers"]: Array.isArray(card?.mobile_numbers) ? card.mobile_numbers.join(", ") : "",
        ["Created Date"]: card?.created_at ? new Date(card.created_at).toDateString() : null,
      }));

      if (excelData.length === 0) {
        alert("No Data to Export");
        return;
      }

      // Create and save the Excel file
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${isBookmarked ? "Bookmarked" : "All"} Cards Data`
      );

      const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });
      const fileName = `${isBookmarked ? "Bookmarked" : "All"} Cards Data_${new Date().toISOString()}.xlsx`;
      saveAs(blob, fileName);

      // Clear selected rows after export
      setSelectedRows([]);

    } catch (error) {
      console.error("Error in exporting cards", error);
      alert("Error occurred in exporting data. Please try again later.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const handleCopyClick = (e, contentType, contentToCopy) => {
    e.preventDefault();
    try {
      navigator?.clipboard?.writeText(contentToCopy).catch((error) => {
        alert("Failed to copy!");
        console.error("COPY FAILED:", error);
      });
      let type;
      switch (contentType) {
        case "emails":
          type = "Emails";
          break;
        case "mobile_numbers":
          type = "Mobile numbers";
          break;
        case "address":
          type = "Address";
          break;
        default:
          type = "Content";
          break;
      }
      toast.success(`${type} copied to clipboard.`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      alert("Failed to copy!");
      console.error("COPY FAILED:", error);
    }
  };

  const handleScanExpandCollapse = (e, uid) => {
    e.stopPropagation();
    e.preventDefault();
    if (_.isEmpty(uid)) {
      alert("UID is not present for the card");
      return;
    }
    setExpandableRows({
      ...expandableRows,
      [uid]: !expandableRows[uid],
    });
  };

  const isAppliedRequestPresent = () => {
    if (
      _.isEmpty(previousFilters?.dateFilter?.startDate) &&
      _.isEmpty(previousFilters?.dateFilter?.endDate) &&
      _.isEmpty(previousFilters?.srFilter) &&
      _.isEmpty(previousFilters?.cardCategoryFilter) &&
      _.isEmpty(previousFilters?.typeFilter)
    ) {
      return false;
    }
    return true;
  };

  const handleRowsPerPageChange = (e) => {
    const newSize = parseInt(e?.target?.value, 10);
    if (newSize) {
      handleRequestChange("size", newSize);
      setRowsPerPage(e?.target?.value);
    }
  };

  return (
    <>
      <div>
        <style>
          {`
            @media (max-width: 1300px) {
              .responsive-table-cell {
                font-size: 12px;
                width: 18%;
              }
            }
            @media (max-width: 1200px) {
              .responsive-table-cell {
                font-size: 13px;
                width: 17%;
              }
            }
            @media (max-width: 1145px) {
              .responsive-table-cell {
                font-size: 12px;
                width: 15%;
              }
            }
            @media (max-width: 1100px) {
              .responsive-table-cell {
                font-size: 10.5px;
                width: 14%;
              }
            }
           @media (min-width:  1000px) and (max-width: 1175px)  {
              .responsive-table-cell {
                font-size: 7px;
                width: 9%;
              }
            }
            @media (max-width: 1000px) {
              .responsive-table-cell {
                font-size: 9px;
                width: 8%;
              }
            }
            .responsive-icon {
              width: 30px;
              height: 30px;
            }
            @media (max-width: 1300px) {
             .responsive-icon {
                width: 24px;
                height: 24px;
              }
            }
            @media (max-width: 1200px) {
             .responsive-icon {
                width: 10px;
                height: 10px;
              }
            }
            @media (min-width:  1060px) and (max-width: 1175px) {
             .responsive-icon {
                width: 15px;
                height: 15px;
              }
            }
              @media (min-width:  1000px) and (max-width: 1059px) {
             .responsive-icon {
                width: 12px;
                height: 12px;
              }
            }
          }
          .responsive-chip {
            font-size: 13px;
            padding: 4px 8px;
          }
          @media (max-width: 1300px) {
            .responsive-chip {
              font-size: 11px;
              padding: 3px 6px;
            }
          }
          @media (max-width: 1200px) {
            .responsive-chip {
              font-size: 10px;
              padding: 2px 5px;
            }
          }
          @media (min-width:  1000px) and (max-width: 1175px)  {
            .responsive-chip {
              font-size: 9px;
              padding: 1px 1px;
            }
          }
        }
        .responsive-button {
          text-transform: none;
          font-size: 1rem;
        }

        @media (max-width: 1300px) {
          .responsive-button {
            font-size: 0.9rem;
            padding: 8px 12px;
        }
      }

     @media (max-width: 1200px) {
      .responsive-button {
        font-size: 0.85rem;
        padding: 7px 11px;
      }
    }

    @media (max-width: 1100px) {
     .responsive-button {
      font-size: 0.8rem;
      padding: 6px 10px;
    }
  }

  @media (max-width: 1000px) {
    .responsive-button {
      font-size: 0.75rem;
      padding: 5px 9px;
    }
  }
     `}
        </style>
        <ThemeProvider theme={theme}>
          <form onSubmit={handleSubmit}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search for a name, company name or designation"
                  value={request?.search}
                  onChange={(e) => {
                    e.preventDefault();
                    // Trim the input value before passing it to the handler
                    handleRequestChange("search", e.target.value.trim());
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      setFilterView(true);
                    }}
                    startIcon={<FilterListIcon />}
                    className="responsive-button"
                    sx={{ textTransform: "none", fontSize: "1rem" }}
                  >
                    Filter
                    {isAppliedRequestPresent() && <span style={{ color: '#00ffa9', fontSize: '1.5rem', marginLeft: '5px' }}>*</span>}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={async (e) => {
                      e.preventDefault();
                      handleExportClick(e);
                    }}
                    sx={{ textTransform: "none", fontSize: "1rem" }}
                    className="responsive-button"
                    startIcon={<FileDownloadIcon />}
                  >
                    Export
                  </Button>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle sx={{ textAlign: "center" }}>Confirm Export</DialogTitle>
                    <DialogContent>Are you sure you want to export the selected data?</DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={handleConfirmExport}
                        sx={{
                          backgroundColor: 'primary.main',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: 'primary.dark',
                          },
                        }}
                        autoFocus
                      >
                        Continue
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      openSelectView();
                    }}
                    startIcon={isSelectView ? <StarIcon /> : <SelectAllIcon />}
                    sx={{ textTransform: "none", fontSize: "1rem" }}
                  >
                    {isSelectView ? "Deselect" : "Select"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRequestChange("reset");
                    }}
                    sx={{ textTransform: "none", fontSize: "1rem" }}
                    startIcon={<RefreshIcon />}
                    className="responsive-button"
                  >
                    Refresh
                  </Button>
                </Grid>
                <Grid item>
                  {tab !== 'bookmarkedCards' && (
                    <Button
                      variant="contained"
                      onClick={handlePopupOpen}
                      sx={{ textTransform: 'none', fontSize: '1rem' }}
                      startIcon={<FileUploadIcon />}
                      className="responsive-button"
                    >
                      Upload
                    </Button>
                  )}
                  <StoredFilesPopup
                    open={storedFilesPopupOpen}
                    handleClose={handleStoredFilesPopupClose}
                    handleNext={() => {
                      handleStoredFilesPopupClose();
                      setAddNewCardsPopupOpen(true);
                    }}
                  />
                  <AddNewCardsPopup
                    open={addNewCardsPopupOpen}
                    handleClose={handleAddNewCardsPopupClose}
                    uploadType={uploadType}
                  />
                  <SelectUploadTypePopup
                    open={selectUploadTypePopupOpen}
                    handleClose={handleSelectUploadTypePopupClose}
                    handleSelection={handleUploadTypeSelection}
                  />
                </Grid>

              </Grid>
            </Grid>
            <FilterPopup
              filterView={filterView}
              setFilterView={setFilterView}
              request={request}
              handleRequestChange={handleRequestChange}
              userCategories={userCategories}
              usersMap={usersMap}
              handleSubmit={handleSubmit}
              initialRequest={initialRequest}
              previousFilters={previousFilters}
              setRequest={setRequest}
            />
          </form>
        </ThemeProvider>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {showCheckboxes && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selectedRows.length > 0 && cards?.length && selectedRows.length < cards.length}
                        checked={cards?.length > 0 && selectedRows.length === cards.length}
                        onChange={handleSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all rows',
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell />
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">
                    Company Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">Designation</TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
                    className="responsive-table-cell"
                  >
                    <Tooltip title="Sales Representative who scanned/added this card" arrow>
                      <img
                        src={profileIcon}
                        alt=""
                        style={{ width: "25px", height: "25px", objectFit: "cover", marginRight: "8px" }}
                      />
                    </Tooltip>
                    SR
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">
                    Category
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">Scan Type</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">
                    Date
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>

                {cards ? (
                  cards.map((row, index) => {
                    const isItemSelected = isSelected(row.uid);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <React.Fragment key={row?.uid}>
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.uid)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          style={{
                            backgroundColor: isItemSelected
                              ? "#DBECFE"
                              : index % 2 === 1
                                ? "white"
                                : "#ECECEC",
                          }}
                        >
                          {showCheckboxes && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, row.uid)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                          )}
                          <TableCell className="responsive-table-cell">
                            {bookamrakLoader[index] ? (
                              <CircularProgress size={20} thickness={7} />
                            ) : (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleBookmarkClick(
                                    e,
                                    row?.isBookmarked,
                                    row?.uid,
                                    index
                                  )
                                }}
                                color="primary"
                              >
                                {row?.isBookmarked ? (
                                  <BookmarkIcon />
                                ) : (
                                  <BookmarkBorderIcon />
                                )}
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell className="responsive-table-cell">
                            {Array.isArray(row?.favicons) && row?.favicons.length > 0 ? (
                              // If favicons array is present
                              <>
                                {!expandedFavicons[row?.uid] ? (
                                  <div style={{ position: 'relative', display: 'inline-block' }}>
                                    {loadingFavicons ? ( // Show loading spinner while loading favicons
                                      <CircularProgress size={30} />
                                    ) : (
                                      <img
                                        src={row?.favicons[0]} // Show the first favicon when loaded
                                        alt="favicon-0"
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                          borderRadius: '50%',
                                          objectFit: 'cover',
                                        }}
                                        draggable="false"
                                      />
                                    )}
                                    {row?.favicons.length > 1 && (
                                      <div
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleFaviconsExpandCollapse(e, row?.uid);
                                        }}
                                        style={{
                                          position: 'absolute',
                                          bottom: '-5px',
                                          right: '-10px',
                                          backgroundColor: '#4285F4',
                                          color: 'white',
                                          borderRadius: '50%',
                                          width: '24px',
                                          height: '24px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        +{row?.favicons.length - 1}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                    {row?.favicons.map((favicon, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          position: 'relative',
                                          display: 'inline-block',
                                          marginRight: '10px',
                                        }}
                                      >
                                        <img
                                          src={favicon}
                                          alt={`favicon-${index}`}
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                          }}
                                          draggable="false"
                                        />
                                      </div>
                                    ))}
                                    <div
                                      onClick={(e) => handleFaviconsExpandCollapse(e, row?.uid)}
                                      style={{
                                        backgroundColor: '#4285F4',
                                        color: 'white',
                                        borderRadius: '50%',
                                        width: '24px',
                                        height: '24px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      -
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              // If no favicons are present, show placeholder image
                              <div style={{ position: 'relative', display: 'inline-block' }}>
                                <img
                                  src={placeholder}
                                  alt="placeholder"
                                  style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell onClick={(e) => handleScanExpandCollapse(e, row?.uid)} className="responsive-table-cell">
                            {row?.person_name}
                          </TableCell>
                          <TableCell
                            onClick={(e) => handleScanExpandCollapse(e, row?.uid)}
                            className="responsive-table-cell"
                          >
                            {row?.company_name}
                          </TableCell>
                          <TableCell
                            onClick={(e) => handleScanExpandCollapse(e, row?.uid)}
                            style={{ marginRight: "0px" }}
                            className="responsive-table-cell"
                          >
                            {row?.designation}
                          </TableCell>
                          <TableCell
                            onClick={(e) => handleScanExpandCollapse(e, row?.uid)}
                            className="responsive-table-cell"
                          >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {usersMap?.[row?.user_id]?.gender === "male" ? (
                                <Tooltip>
                                  <img
                                    src={maleIcon}
                                    alt="Male Icon"
                                    style={{ width: "25px", height: "25px", objectFit: "cover", paddingRight: "4px" }}
                                  />
                                </Tooltip>
                              ) : usersMap?.[row?.user_id]?.gender === "female" ? (
                                <Tooltip>
                                  <img
                                    src={femaleIcon}
                                    alt="Female Icon"
                                    style={{ width: "25px", height: "25px", objectFit: "cover", paddingRight: "4px" }}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip>
                                  <img
                                    src={profileIcon}
                                    alt="Profile Icon"
                                    style={{ width: "25px", height: "25px", objectFit: "cover", paddingRight: "4px" }}
                                  />
                                </Tooltip>
                              )}
                              {usersMap?.[row?.user_id]?.displayName || "ADMIN"}
                            </div>
                          </TableCell>
                          <TableCell className="responsive-table-cell">
                            {row?.categories?.length > 1 ? (
                              <>
                                <Chip
                                  key={row?.categories[0]}
                                  label={row?.categories[0]}
                                  className="responsive-chip"
                                  sx={{ color: "white", backgroundColor: userCategoriesColorMap?.[row?.categories[0]] || "#875053" }}
                                />
                                {!expandedCategories[row?.uid] ? (
                                  <div style={{ display: "block" }}>
                                    <Chip
                                      label={`+${row?.categories?.length - 1}`}
                                      className="responsive-chip"
                                      sx={{
                                        marginLeft: "4px",
                                        backgroundColor: "#49C1C9",
                                        color: "white",
                                        marginTop: "4px"
                                      }}
                                      onClick={(e) => handleCategoryExpandCollapse(e, row?.uid)}
                                    />
                                  </div>
                                ) : (
                                  <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
                                    {row?.categories?.slice(1).map((category) => (
                                      <Chip
                                        key={category}
                                        label={category}
                                        className="responsive-chip"
                                        sx={{
                                          color: "white",
                                          backgroundColor: userCategoriesColorMap?.[category] || "#875053",
                                          marginBottom: "4px",
                                        }}
                                      />
                                    ))}
                                    <Chip
                                      label="-"
                                      className="responsive-chip"
                                      sx={{
                                        marginLeft: "5px",
                                        backgroundColor: "#49C1C9",
                                        color: "white",
                                      }}
                                      onClick={(e) => handleCategoryExpandCollapse(e, row?.uid)}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              row?.categories?.map((category) => (
                                <Chip
                                  key={category}
                                  label={category}
                                  className="responsive-chip"
                                  sx={{ color: "white", backgroundColor: userCategoriesColorMap?.[category] || "black" }}
                                />
                              ))
                            )}
                          </TableCell>
                          <TableCell onClick={(e) => handleScanExpandCollapse(e, row?.uid)} className="responsive-table-cell">
                            <Chip
                              label={(row?.type && cardTypeMap?.[row.type]?.label) || row?.type}
                              className="responsive-chip"
                              sx={{
                                color: "white",
                                backgroundColor:
                                  (row?.type && cardTypeMap?.[row.type]?.color) || "",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            onClick={(e) => handleScanExpandCollapse(e, row?.uid)}
                            className="responsive-table-cell"
                          >
                            {row?.created_at
                              ? new Date(row?.created_at).toLocaleDateString(undefined, {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })
                              : null}
                          </TableCell>
                          <TableCell colSpan={3} style={{ textAlign: "left" }} className="responsive-table-cell">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {!_.isEmpty(row?.mobile_numbers) && (
                                  <Button
                                    variant="text"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCopyClick(e, "mobile_numbers", row?.mobile_numbers)
                                    }}
                                    style={{ padding: 0, minWidth: 'auto', margin: '0 2px' }}
                                  >
                                    <img
                                      src={callIcon}
                                      alt="Call Icon"
                                      className="responsive-icon"
                                      style={{ cursor: "pointer", margin: '0 2px' }}
                                    />
                                  </Button>
                                )}
                                {!_.isEmpty(row?.emails) && (
                                  <Button
                                    variant="text"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCopyClick(e, "emails", row?.emails)
                                    }}
                                    style={{ padding: 0, minWidth: 'auto', margin: '0 2px' }}
                                  >
                                    <img
                                      src={mailIcon}
                                      alt="Mail Icon"
                                      className="responsive-icon"
                                      style={{ cursor: "pointer", margin: '0 2px' }}
                                    />
                                  </Button>
                                )}
                                {!_.isEmpty(row?.address) && (
                                  <Button
                                    variant="text"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCopyClick(e, "address", row?.address)
                                    }}
                                    style={{ padding: 0, minWidth: 'auto', margin: '0 2px' }}
                                  >
                                    <img
                                      src={locationIcon}
                                      alt="Location Icon"
                                      className="responsive-icon"
                                      style={{ cursor: "pointer", margin: '0 2px' }}
                                    />
                                  </Button>
                                )}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow key={`${row?.uid}-details`}>
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              backgroundColor: "#f4f4f4",
                              width: "100%",
                            }}
                            colSpan={11}
                            className="responsive-table-cell"
                          >
                            <Collapse
                              in={expandableRows[row.uid]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={2}>
                                <CardDetails
                                  cards={cards}
                                  setCards={setCards}
                                  index={index}
                                  initialCardData={row}
                                  usersMap={usersMap}
                                  userCategories={userCategories}
                                  handleUpdateCardsCall={handleUpdateCardsCall}
                                  handleCopyClick={handleCopyClick}
                                />
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} style={{ borderBottom: "none" }} className="responsive-table-cell">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 436,
                          width: "100%",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <TablePagination
              component="div"
              count={total || 0}
              rowsPerPageOptions={[10, 25, 50]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={currentPage}
              onPageChange={(e, newPage) => {
                e.preventDefault();
                handleRequestChange("page", newPage);
              }}
              ActionsComponent={TablePaginationActions}
              slotProps={{
                select: {
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                      },
                      component: styled('div')(({ theme }) => ({
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        scrollbarWidth: 'none'
                      }))
                    },
                  },
                },
              }}
            />
          </div>
        </Paper>
        {isSelectView && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: '#fff',
              padding: '8px 12px',
              borderRadius: '8px',
              boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
              border: '1px solid #d9d9d9',
              width: '450px',
              margin: '20px auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '16px',
              }}
            >
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: '#e0f3ff',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '8px',
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#007bff' }}>
                  {selectedCount}
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#000' }}> {/* "Selected" in black */}
                Selected
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: '#007bff',
                  color: '#fff',
                  marginRight: '8px',
                  padding: '6px 12px',
                  textTransform: 'none',
                  borderColor: '#007bff',
                  '&:hover': {
                    backgroundColor: '#0056b3',
                    color: '#fff',
                    borderColor: '#007bff',
                  },
                }}
                startIcon={<BookmarkIcon />}
                onClick={async (e) => {
                  e.preventDefault();
                  handleBookmarkClick(e);
                  setSelectedRows([]);
                }}
              >
                Bookmark
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#007bff',
                  color: '#fff',
                  marginRight: '8px',
                  padding: '6px 12px',
                  textTransform: 'none',
                  borderColor: '#007bff',
                  '&:hover': {
                    backgroundColor: '#0056b3',
                    color: '#fff',
                    borderColor: '#007bff',
                  },
                }}
                onClick={handleMarkForImprovementDialogOpen}
              >
                Mark for improvement
              </Button>
              <MarkForImprovementPopup
                isPopupOpen={markForImprovementDialogOpen}
                handlePopupClose={handleMarkForImprovementDialogClose}
                cardData={isSelectedAll ? (cards || []) : (cards || []).filter(card => selectedRows.includes(card.uid))}
                setIsMarkedForImprovement={setIsMarkedForImprovement}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </Box>
          </Box>
        )}

      </div>
      <ToastContainer />
    </>
  );
}

export default CardsContainer;
