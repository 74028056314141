import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
    Grid,
    TextField,
    InputAdornment,
    Button,
    ThemeProvider,
    createTheme,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    Collapse,
    Box,
    IconButton,
    TablePagination,
    CircularProgress
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import question from '../../assets/icons/question.svg';
import FilterPopup from './popups/FilterPopups';
import DraftDetails from './DraftDetails';
import tableEdit from '../../assets/icons/table_edit.svg';
import Cookies from 'js-cookie';
import TablePaginationActions from "../cards/TablePaginationAction";
import MarkForImprovementPopup from "./popups/MarkForImprovementPop";
import { path } from "../../apiCentral/path";

const defaultPageSize = 10;

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputBase-root": {
                        height: 40,
                        marginBottom: 20,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 40,
                    marginBottom: 20,
                },
            },
        },
    },
});

function DraftPage({ userCategories }) {
    const [filterView, setFilterView] = useState(false);
    const [filters, setFilters] = useState({});
    const [expandedRows, setExpandedRows] = useState({});
    const [draftData, setDraftData] = useState([]);
    const [editMode, setEditMode] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [dataProcess, setDataProcess] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchString, setSearchString] = useState("");
    const [openMarkForImprovementPopup, setOpenMarkForImprovementPopup] = useState(false);
    const [markedForImprovement, setMarkedForImprovement] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rowScanUid, setRowScanUid] = useState("");
    // console.log(draftData);

    // Re-fetch data when currentPage, rowsPerPage, searchString, or filters change.
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = Cookies.get('token');

                let requestPayload = {
                    page: currentPage + 1,
                    size: rowsPerPage,
                    search: searchString
                };
                if (filters["startDate"]) {
                    requestPayload["start_date"] = filters["startDate"];

                    if (filters["endDate"]) {
                        requestPayload["end_date"] = filters["endDate"];
                    } else {
                        const currentDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
                        requestPayload["end_date"] = currentDate;
                    }
                }

                if (filters["dataProcess"]) {
                    requestPayload["data_process"] = filters["dataProcess"];
                }

                const response = await axios.post(
                    path.draft,
                    requestPayload,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json',
                        }
                    }
                );
                setDraftData(response.data.result[0].cards || []);
                setTotal(response.data.result[0].total || 0);
            } catch (error) {
                console.error('Error fetching draft data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [currentPage, rowsPerPage, searchString, filters]);

    // console.log(total)

    const handleFilterClick = () => setFilterView(true);
    const handleFilterClose = () => setFilterView(false);

    const handleSubmit = () => {
        setFilters({ startDate, endDate, dataProcess })
        setCurrentPage(0); // Reset to the first page
        setFilterView(false); 
    }

    const handleResetFilters = () => {
        setDataProcess('');
        setStartDate('');
        setEndDate('');
        setFilters({});
        setCurrentPage(0);
        setFilterView(false); // Close the popup
    };    

    // Toggle the expanded state for a specific row.
    const handleExpandClick = (index) => {
        setExpandedRows(prev => ({
            ...prev,
            [index]: !prev[index],
        }));

        if (!expandedRows[index]) {
            setDraftData(prevData => prevData.map((item, idx) =>
                idx === index ? { ...item, extraData: { website_url: 'example.com', emails: ['example@example.com'], mobile_numbers: ['123-456-7890'] } } : item
            ));
        }
    };

    // Add extra data to a specific row when expanded.
    const handleEditToggle = (index) => {
        setEditMode(prev => ({
            ...prev,
            [index]: true,
        }));
        setExpandedRows(prev => ({
            ...prev,
            [index]: true,
        }));
    };

    // Enable edit mode for a specific row and expand it.
    const handleRequestChange = (key, value) => {
        if (key === "page") {
            setCurrentPage(value);
        } else if (key === "size") {
            setRowsPerPage(value);
            setCurrentPage(0);
        }
    };

    const handleRowsPerPageChange = (event) => handleRequestChange("size", parseInt(event.target.value, 10));

    const handleQuestionClick = (doc) => {
        setOpenMarkForImprovementPopup(true)
        setRowScanUid(doc._id)
     };
    const handleMarkForImprovementClose = () => setOpenMarkForImprovementPopup(false);

    const handleSetMarkedForImprovement = (value) => {
        setMarkedForImprovement(value);
    };

    const convertToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2);
    const handleSearchChange = (event) => setSearchString(event.target.value);

    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search by file name"
                            value={searchString}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item container xs={12} sm={6} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={handleFilterClick}
                                startIcon={<FilterListIcon />}
                                className="responsive-button"
                                sx={{ textTransform: "none", fontSize: "1rem" }}
                            >
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {filterView && (
                <FilterPopup
                    onClose={handleFilterClose}
                    handleSubmit={handleSubmit}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    dataProcess={dataProcess}
                    setDataProcess={setDataProcess}
                    handleResetFilters={handleResetFilters}
                />
            )}
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>File Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Size</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Photo Upload</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Data Process</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Updated Date</TableCell>
                                <TableCell sx={{ width: '20%' }} />
                                <TableCell sx={{ width: '20%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        <CircularProgress /> {/* Loading spinner */}
                                    </TableCell>
                                </TableRow>
                            ) : draftData.length > 0 ? (
                                draftData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            style={{
                                                backgroundColor: index % 2 === 1 ? "white" : "#ECECEC",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <TableCell
                                                sx={{ width: '20%' }}
                                                onClick={() => handleExpandClick(index)}
                                            >
                                                {item.front_img_filename}
                                            </TableCell>
                                            <TableCell
                                                sx={{ width: '20%' }}
                                                onClick={() => handleExpandClick(index)}
                                            >
                                                {convertToMB(item.front_img_size)} MB
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }}>
                                                <Chip
                                                    label={"Success"}
                                                    sx={{
                                                        color: "white",
                                                        backgroundColor: "#46B973",
                                                        cursor: "default",
                                                        "&:hover": {
                                                            backgroundColor: "#46B973",
                                                        },
                                                    }}
                                                    onClick={() => handleExpandClick(index)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }}>
                                                <Chip
                                                    label={item.extract_img_operation ? "InProgress" : "Success"}
                                                    sx={{
                                                        color: "white",
                                                        backgroundColor: !item.extract_img_operation ? "#46B973" : "#D32F2F",
                                                        cursor: "default",
                                                        "&:hover": {
                                                            backgroundColor: !item.extract_img_operation ? "#46B973" : "#D32F2F",
                                                        },
                                                    }}
                                                    onClick={() => handleExpandClick(index)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }} onClick={() => handleExpandClick(index)}>
                                                {new Date(item.updated_at).toLocaleDateString(undefined, {
                                                    year: "numeric",
                                                    month: "short",
                                                    day: "numeric",
                                                })}
                                            </TableCell>
                                            <TableCell colSpan={1} style={{ textAlign: "left" }}>
                                                <IconButton
                                                    onClick={() => handleEditToggle(index)}
                                                    style={{ color: "black", borderRadius: "0" }}
                                                >
                                                    <img
                                                        src={tableEdit}
                                                        alt="Edit"
                                                        style={{ cursor: "pointer", width: "30px", height: "30px", borderRadius: "0" }}
                                                    />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <img
                                                    src={question}
                                                    alt="MFI"
                                                    style={{ cursor: 'pointer', width: "30px", height: "30px", borderRadius: "0" }}
                                                    onClick={() => handleQuestionClick(item)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                style={{
                                                    paddingBottom: 0,
                                                    paddingTop: 0,
                                                    backgroundColor: "#ECECEC",
                                                }}
                                                colSpan={7}
                                            >
                                                <Collapse in={expandedRows[index]} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <DraftDetails
                                                            editMode={editMode[index]}
                                                            data={draftData[index]}
                                                            userCategories={userCategories}
                                                            setEditMode={setEditMode}
                                                        />
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={Array.from({ length: 50 }, (_, index) => index + 1)}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={(event, newPage) => handleRequestChange("page", newPage)}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <MarkForImprovementPopup
                isPopupOpen={openMarkForImprovementPopup}
                handlePopupClose={handleMarkForImprovementClose}
                data={rowScanUid}
                setIsMarkedForImprovement={handleSetMarkedForImprovement}
            />
        </ThemeProvider>
    );
}

export default DraftPage;
