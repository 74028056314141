import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import LoginContainer from "./login/LoginContainer";
import DashboardContainer from "./dashboard/DashboardContainer";
import { useEffect } from "react";
import { refreshToken } from "./config/firebase/firebase";
import ReactGA from 'react-ga4';
import { SnackbarProvider } from 'notistack';

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-19329D023T', {
      debugMode: true, 
    });
  }, []);

  const LocationTracker = () => {
    const location = useLocation();

    useEffect(() => {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
        debug_mode: true,
      });
    }, [location]);

    return null;
  };

  useEffect(() => {
    // console.log("REFRESH TOKEN CALLED");
    refreshToken()
    setInterval(refreshToken, 3580 * 1000);
  }, []);

  return (
    <>
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <LocationTracker />
        <Routes>
          <Route path="/" element={<LoginContainer />} />
          <Route path="/CardHouse" element={<DashboardContainer />} />
        </Routes>
      </BrowserRouter>
      </SnackbarProvider>
    </>
  );
}

export default App;
