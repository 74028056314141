import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Button,
    Box,
    Dialog,
    DialogTitle,
    TextField,
    DialogActions,
    DialogContent,
    Chip,
} from "@mui/material";
import apiHelper from "../../apiCentral/apiHelper";
import { path } from "../../apiCentral/path";
const _ = require("lodash");

function UserCategoriesContainer({
    userCategories,
    userCategoriesColorMap,
    setUserCategories,
    handleGetUserCategoryCall
}) {
    const [isUserCategoryPopupOpen, setIsUserCategoryPopupOpen] = useState(false);
    const [newUserCategory, setNewUserCategory] = useState("");

    const handleUserCategoryPopupOpen = (e) => {
        e.preventDefault();
        if (userCategories?.length > 20) {
            alert("User categories should not exceed 20");
        } else {
            setIsUserCategoryPopupOpen(true);
        }
    };

    const handleUserCategoryPopupClose = (e) => {
        e.preventDefault();
        setIsUserCategoryPopupOpen(false);
    };

    const handleAddNewUserCategory = (e) => {
        setNewUserCategory(e.target.value);
    };

    const addNewUserCategory = async (e) => {
        e.preventDefault();
        let response;
        try {
            if (userCategories?.length > 20) {
                setIsUserCategoryPopupOpen(false);
                setNewUserCategory(null);
                alert("User categories should not exceed 20");
                return;
            }
            if (userCategories.some(categories => _.toLower(categories?.userCategory) === _.toLower(newUserCategory))) {
                setNewUserCategory(null);
                alert("User Category already exists");
                return;
            }
            setUserCategories(null);
            response = await apiHelper.post(path?.addUserCategory, {
                userCategory: newUserCategory,
            });
            window.gtag('event', 'add_user_category', {
                event_category: 'User Management',
                event_label: newUserCategory,
                value: 1,
                debug_mode: true,
            });
            setIsUserCategoryPopupOpen(false);
            handleGetUserCategoryCall();
            setNewUserCategory(null);
        } catch (error) {
            alert("Internal Server Error! Please Try Again");
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "40px",
                    marginBottom: "20px",
                    position: "relative",
                    height: "45px",
                }}
            >
                <h3 >User Categories</h3>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUserCategoryPopupOpen}
                    sx={{
                        textTransform: "none",
                        fontSize: "1rem",
                        padding: "12px",
                        lineHeight: 1.2,
                    }}
                >
                    Add Category
                </Button>
            </Box>
            <Dialog
                open={isUserCategoryPopupOpen}
                onClose={handleUserCategoryPopupClose}
            >
                <DialogTitle>Add User Category</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="newUserCategory"
                        label="New User Category"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newUserCategory || ""}
                        onChange={handleAddNewUserCategory}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUserCategoryPopupClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={addNewUserCategory}
                        color="primary"
                        disabled={!newUserCategory}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            {userCategories ? (
                <Paper
                    sx={{
                        overflow: "hidden",
                        border: "1px solid #d0d0d0",
                        height: "300px",
                    }}
                >
                    <TableContainer sx={{ maxHeight: 300 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }}>Categories</TableCell>
                                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Count</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userCategories && userCategories.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Chip
                                                key={row?.userCategory}
                                                label={row?.userCategory}
                                                sx={{ color: "white", backgroundColor: row?.userCategory && userCategoriesColorMap?.[row.userCategory] || "black" }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{row?.count}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ) : (
                <CircularProgress />
            )}
        </>
    );
}

export default UserCategoriesContainer;
