// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
    padding: 10px !important;
    position: relative;
}

.count-card-text {
    font-size: 17px !important;
    font-weight: 500 !important;
    line-height: 30px !important;
    text-align: left !important;
}

.count-card-number {
    font-size: 31px !important;
    font-weight: 700 !important;
    line-height: 58.8px !important;
    text-align: left !important;
    z-index: 2 !important;
}


@media (max-width: 768px) {
    .count-card-text {
        font-size: 20px !important;
        line-height: 24px !important;
    }

    .count-card-number {
        font-size: 36px !important;
        line-height: 43px !important;
    }
}

@media (max-width: 480px) {
    .count-card-text {
        font-size: 16px !important;
        line-height: 19.2px !important;
    }

    .count-card-number {
        font-size: 28px !important;
        line-height: 33.6px !important;
    }
}

.footer-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    height: auto;
    opacity: 40%;
    z-index: 1;
    border-radius: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/analytics/Cards/totalCards.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;IAC3B,4BAA4B;IAC5B,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;IAC3B,8BAA8B;IAC9B,2BAA2B;IAC3B,qBAAqB;AACzB;;;AAGA;IACI;QACI,0BAA0B;QAC1B,4BAA4B;IAChC;;IAEA;QACI,0BAA0B;QAC1B,4BAA4B;IAChC;AACJ;;AAEA;IACI;QACI,0BAA0B;QAC1B,8BAA8B;IAClC;;IAEA;QACI,0BAA0B;QAC1B,8BAA8B;IAClC;AACJ;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,2BAA2B;AAC/B","sourcesContent":[".card-container {\n    padding: 10px !important;\n    position: relative;\n}\n\n.count-card-text {\n    font-size: 17px !important;\n    font-weight: 500 !important;\n    line-height: 30px !important;\n    text-align: left !important;\n}\n\n.count-card-number {\n    font-size: 31px !important;\n    font-weight: 700 !important;\n    line-height: 58.8px !important;\n    text-align: left !important;\n    z-index: 2 !important;\n}\n\n\n@media (max-width: 768px) {\n    .count-card-text {\n        font-size: 20px !important;\n        line-height: 24px !important;\n    }\n\n    .count-card-number {\n        font-size: 36px !important;\n        line-height: 43px !important;\n    }\n}\n\n@media (max-width: 480px) {\n    .count-card-text {\n        font-size: 16px !important;\n        line-height: 19.2px !important;\n    }\n\n    .count-card-number {\n        font-size: 28px !important;\n        line-height: 33.6px !important;\n    }\n}\n\n.footer-image {\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    width: 25%;\n    height: auto;\n    opacity: 40%;\n    z-index: 1;\n    border-radius: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
