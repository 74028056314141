// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  display: flex;
  height: 100vh;
}

.menu-container {
  flex: 0 0 15%; 
  background-color: white;
  padding: 20px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 38px;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-container button {
  margin-bottom: 25px;
}

.logout-button {
  margin-top: auto; 
}

.component-display {
  flex-grow: 1; 
  padding: 20px;
  overflow-y: auto;
  margin-top: 50px;
  background-color: #F8F9FA;
  border-top-left-radius: 15px;
}

.highlighted-view {
  background-color: #F0F8FF;
  color: #007BFF;
  border-left: 5px solid #5D5FEF;
  width: 100%;
}

.normal-view {
  background-color: inherit;
  color: black;
  border-left: none;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/dashboardContainer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".dashboard-container {\n  display: flex;\n  height: 100vh;\n}\n\n.menu-container {\n  flex: 0 0 15%; \n  background-color: white;\n  padding: 20px;\n  padding-left: 0px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin-top: 38px;\n}\n\n.menu-buttons {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.menu-container button {\n  margin-bottom: 25px;\n}\n\n.logout-button {\n  margin-top: auto; \n}\n\n.component-display {\n  flex-grow: 1; \n  padding: 20px;\n  overflow-y: auto;\n  margin-top: 50px;\n  background-color: #F8F9FA;\n  border-top-left-radius: 15px;\n}\n\n.highlighted-view {\n  background-color: #F0F8FF;\n  color: #007BFF;\n  border-left: 5px solid #5D5FEF;\n  width: 100%;\n}\n\n.normal-view {\n  background-color: inherit;\n  color: black;\n  border-left: none;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
