import React, { useState } from "react";
import CountCard from "./Cards/CountCard";
import HorizontalBarChart from "./Cards/HorizontalBarChart";
import {
  Button,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from "@mui/material";
import MultiLineChart from "./Charts/MultiLineChart";
import MultiBarChart from "./Charts/MultiBarChart";
import RefreshIcon from "@mui/icons-material/Refresh";
import maleIcon from "../../assets/icons/male_icon.svg";
import femaleIcon from "../../assets/icons/female_icon.svg";
import profileIcon from "../../assets/icons/profile_icon.svg";
import placeholder from '../../assets/icons/placeholder.svg';
import { cardTypeMap } from "../../contants";
const _ = require("lodash");

function AnalyticsContainer({
  cardScanDistribution,
  periodWiseCardScanDistribution,
  cardSourceDistribution,
  companyCardCount,
  totalCards,
  cards,
  topEmployeeChart,
  periodWiseTopEmployeeChart,
  totalUsers,
  usersMap,
  userCategoriesColorMap,
  initialApiCalls,
  userType
}) {
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedFavicons, setExpandedFavicons] = useState({});

  const handleFaviconsExpandCollapse = (uid) => {
    setExpandedFavicons((prev) => ({
      ...prev,
      [uid]: !prev[uid],
    }));
  };

  const handleCategoryExpandCollapse = (uid) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [uid]: !prev[uid],
    }));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            initialApiCalls();
          }}
          sx={{ textTransform: "none", fontSize: "1rem" }}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          height: "120px",
        }}
      >
        <div style={{ flexBasis: "20%", marginRight: "20px", height: "60%", width: "30px" }}>
          <CountCard count={totalCards} label={"Total Cards"} variant={"totalCards"} />
        </div>
        {userType === "ORG" && (
        <div style={{ flexBasis: "20%", height: "60%", width: "30px", marginLeft: "15px" }}>
          <CountCard count={totalUsers} label={"No. Of Employees"} variant={"noOfEmployees"} />
        </div>
        )}
        <div style={{  flexGrow: 1, paddingLeft: "37px", width: "40px" }}>
          <HorizontalBarChart
            leftNumber={cardSourceDistribution ? (cardSourceDistribution?.scan ? cardSourceDistribution?.scan : 0) : null}
            rightNumber={cardSourceDistribution ? (cardSourceDistribution?.contact ? cardSourceDistribution?.contact : 0) : null}
            label={"Card Distribution"}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "calc(60vh - 32px)", // Consistent height
          flexWrap: "wrap",
          paddingBottom: "20px",
        }}
      >
        <div
          style={{
            flex: "1 1 60%",
            marginRight: "20px",
            minWidth: "200px",
            maxWidth: "65%",
            display: "flex",
            flexDirection: "column",
            height: "100%", // Ensures full height
          }}
        >
          <Typography
            component="div"
            sx={{ padding: 1 }}
            color="textSecondary"
          >
            Card Scan Distribution
          </Typography>
          <div style={{ flex: 1, width: "100%", overflow: "hidden" }}>
            <MultiBarChart
              data={periodWiseCardScanDistribution}
              style={{ height: "100%", width: "100%" }} // Ensures chart takes full height
              xAxis={{ labelRotation: 45 }}
            />
          </div>
        </div>

        <div
          style={{
            flex: "1 1 30%", // Same flex sizing as the first div
            display: "flex",
            flexDirection: "column",
            minWidth: "300px",
            maxWidth: "30%",
            height: "100%", // Ensures full height
            boxSizing: "border-box",
          }}
        >
          <Typography
            component="div"
            sx={{
              padding: 1,
            }}
            color="textSecondary"
          >
            Top Companies
          </Typography>
          <div style={{ flex: 1, width: "100%", height: "100%", overflow: "hidden" }}>
            <Paper sx={{ width: "100%", height: "100%" }}>
              <TableContainer
                style={{
                  height: "100%",
                  overflowY: "auto",  // Enable vertical scrolling
                  scrollbarWidth: "thin", // Custom scrollbar width (for Firefox)
                  msOverflowStyle: "none", // Hide scrollbar (for IE)
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Company Name</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companyCardCount && companyCardCount.length > 0 ? (
                      companyCardCount.map(([name, count], index) => (
                        <TableRow
                          key={index}
                          style={{ backgroundColor: index % 2 === 0 ? "white" : "#ECECEC" }}
                        >
                          <TableCell sx={{ fontSize: "13px", padding: "30px", display: "flex" }}>{name}</TableCell>
                          <TableCell align="center" sx={{ fontSize: "13px", padding: "30px" }}>{count}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} style={{ borderBottom: "none" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <CircularProgress />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flexGrow: 1, maxWidth: "100%", maxHeight: "100%", marginBottom: 0 }}>
          <Typography
            component="div"
            sx={{
              padding: 1,
            }}
            color="textSecondary"
          >
            Recent Scans
          </Typography>
          <Paper sx={{ maxWidth: "100%", overflow: "hidden" }}>
            <TableContainer style={{ maxHeight: "100%", maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table" style={{ maxWidth: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", width: "7%" }}>Scanned By</TableCell>
                    <TableCell sx={{ width: "5%" }} />
                    <TableCell sx={{ fontWeight: "bold", width: "13.5%" }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "13.5%" }}>Company Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "13.5%" }}>Designation</TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "13.5%" }}>Category</TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "9%" }}>Scan type</TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "9%" }}>Created date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cards ? (
                    cards.map((row, index) => (
                      <TableRow key={row?.uid} style={{ backgroundColor: index % 2 === 0 ? "white" : "#ECECEC" }}>
                        <TableCell>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                overflow: "hidden",
                                display: "inline-block",
                                marginRight: "8px"
                              }}
                            >
                              {usersMap?.[row?.user_id]?.gender === "male" ? (
                                <Tooltip>
                                  <img
                                    src={maleIcon}
                                    alt="Male Icon"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                  />
                                </Tooltip>
                              ) : usersMap?.[row?.user_id]?.gender === "female" ? (
                                <Tooltip>
                                  <img
                                    src={femaleIcon}
                                    alt="Female Icon"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                  />
                                </Tooltip>
                              ) : <Tooltip>
                                <img
                                  src={profileIcon}
                                  alt="Profile Icon"
                                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                              </Tooltip>}
                            </div>
                            {usersMap?.[row?.user_id]?.displayName || "ADMIN"}
                          </div>
                        </TableCell>
                        <TableCell className="responsive-table-cell">
                          {Array.isArray(row?.favicons) && row?.favicons.length > 0 && typeof row.favicons[0] === 'string' && row?.favicons[0]?.split('/')[0] !== "favicons" ? (
                            <>
                              {!expandedFavicons[row?.uid] ? (
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                  <img
                                    src={row.favicons[0]}
                                    alt="favicon-0"
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  {row?.favicons.length > 1 && ( // Add this condition to check if favicons length is more than 1
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleFaviconsExpandCollapse(e, row?.uid);
                                      }}
                                      style={{
                                        position: 'absolute',
                                        bottom: '-5px',
                                        right: '-10px',
                                        backgroundColor: '#4285F4',
                                        color: 'white',
                                        borderRadius: '50%',
                                        width: '24px',
                                        height: '24px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      +{row?.favicons.length - 1}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                  {row.favicons.map((favicon, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginRight: '10px',
                                      }}
                                    >
                                      <img
                                        src={favicon}
                                        alt={`favicon-${index}`}
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                          borderRadius: '50%',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    </div>
                                  ))}
                                  <div
                                    onClick={(e) => handleFaviconsExpandCollapse(e, row?.uid)}
                                    style={{
                                      backgroundColor: '#4285F4',
                                      color: 'white',
                                      borderRadius: '50%',
                                      width: '24px',
                                      height: '24px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    -
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                              <img src={placeholder} alt="" />
                            </div>
                          )}
                        </TableCell>
                        <TableCell>{row?.person_name}</TableCell>
                        <TableCell>{row?.company_name}</TableCell>
                        <TableCell>{row?.designation}</TableCell>
                        <TableCell>
                          {row?.categories?.length > 1 ? (
                            <>
                              <Chip
                                key={row?.categories[0]}
                                label={row?.categories[0]}
                                sx={{
                                  color: "white",
                                  backgroundColor: userCategoriesColorMap?.[row?.categories[0]] || "#875053",
                                  marginLeft: "1px",
                                }}
                              />
                              {!expandedCategories[row?.uid] ? (
                                <div style={{ display: "block" }}>
                                  <Chip
                                    label={`+${row?.categories?.length - 1}`}
                                    sx={{
                                      marginLeft: "4px",
                                      backgroundColor: "#49C1C9",
                                      color: "white",
                                      marginTop: "4px"
                                    }}
                                    onClick={() => handleCategoryExpandCollapse(row?.uid)}
                                  />
                                </div>
                              ) : (
                                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
                                  {row?.categories?.slice(1).map((category) => (
                                    <Chip
                                      key={category}
                                      label={category}
                                      sx={{
                                        color: "white",
                                        backgroundColor: userCategoriesColorMap?.[category] || "#875053",
                                        marginBottom: "4px",
                                        marginLeft: "4px",
                                      }}
                                    />
                                  ))}
                                  <Chip
                                    label="-"
                                    sx={{ marginLeft: "5px", backgroundColor: "#49C1C9", color: "white" }}
                                    onClick={() => handleCategoryExpandCollapse(row?.uid)}
                                  />
                                </div>
                              )}
                            </>

                          ) : (
                            row?.categories?.map((category) => (
                              <Chip
                                key={category}
                                label={category}
                                sx={{ color: "white", backgroundColor: userCategoriesColorMap?.[category] || "black", marginLeft: "5px" }}
                              />
                            ))
                          )}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={cardTypeMap?.[row?.type]?.label || ""}
                            sx={{
                              color: "white",
                              backgroundColor: cardTypeMap?.[row?.type]?.color || "",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {row?.created_at
                            ? new Date(row?.created_at).toLocaleDateString(undefined, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                            : null}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} style={{ borderBottom: "none" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "450px",
                            width: "100%",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div >
      {userType === "ORG" && (
      <div style={{ flexBasis: 'calc(50% - 10px)', marginTop: "20px" }}>
        <Typography
          component="div"
          sx={{
            padding: 1,
          }}
          color="textSecondary"
        >
          Top Employees
        </Typography>
        <div style={{ padding: "0px", width: "100%", height: "380px" }}>
          <MultiLineChart
            data={periodWiseTopEmployeeChart}
            style={{ height: "100%" }}
          />
        </div>
      </div>
      )}
    </>
  );
}

export default AnalyticsContainer;
